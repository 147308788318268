<template>
  <div class=" bg-gray-100 relative z-10">
        <img src="@/assets/img/resturant-header-img.jpg" class="w-full h-300 object-cover" alt="">
        <div class="absolute top-0 w-full h-full bg-overlay flex justify-center items-center">
            <div class="text-gray-100">
                <h2 class="lg:text-4xl text-3xl font-semibold text-center">{{name}}</h2>
                <div v-if="address">
                     <p class="text-sm text-center ">{{ address }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
 name:"Title",
 props:['name','address']
}
</script>

<style>

</style>