<template>
        <Header/>
        <Title name="Payment Information"/>
        <div class="bg-gray-100">
            <div class="mx-3 lg:mx-16 flex justify-center py-8" >
                <div class="bg-white shadow-md p-4 container rounded ">
                        <h1 class="text-xl lg:text-4xl font-semibold my-4 text-center">Thank you for your order!</h1>
                        <div class="grid lg:grid-cols-2">
                        <div class="mt-5 lg:mt-0">
                            <h1 class="text-base lg:text-xl">Order Information</h1>
                            <div class="grid grid-cols-2 w-full  mt-4">
                                <div>
                                    <h5 class="font-semibold" >Reference ID</h5>
                                </div>
                                <div>
                                    <h5 class="" >{{ getOrder.data.orderReferenceNumber }}</h5>
                                </div>
                                <div>
                                    <h5 class="font-semibold" >Full Name</h5>
                                </div>
                                <div>
                                    <h5 class="" >{{ getOrder.data.contactPersonName }}</h5>
                                </div>
                                <div>
                                    <h5 class="font-semibold" >Phone Number</h5>
                                </div>
                                <div>
                                    <h5 class="" >{{ getOrder.data.phoneNumber }}</h5>
                                </div>
                                <div>
                                <h5 class="font-semibold" >Delivery address</h5>
                                </div>
                                <div>
                                    <h5 class="" >{{ getOrder.data.address }}</h5>
                                </div>
                                 <div>
                                <h5 class="font-semibold" >Additional Information</h5>
                                </div>
                                <div>
                                    <h5 class="" >{{ (getOrder.data.additionInfo)? getOrder.data.additionInfo : "-" }}</h5>
                                </div>
                                <div>
                                    <h5 class="font-semibold" >Total Cost</h5>
                                </div>
                                <div>
                                    <h5 class="">&#8358;{{ formatNumber(getOrder.data.totalCost) }}</h5>
                                </div>
                            </div>
                            <div class="lg:grid lg:grid-cols-2 " >
                                <div>
                                    <h5 class="font-semibold">Products</h5>                                </div>
                                <div>
                                    <h5 v-for="cart in getOrder.data.cartItems" :key="cart" class="" > <span>{{ cart.food.name }}</span> X {{ cart.quantity }}</h5>
                                </div>
                            </div>
                            <div class="mt-8 flex space-x-2 col-span-2">
                                <div class="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <span>
                                    We will notify you once your order has been confirmed
                                </span>
                            </div>
                            <div class="lg:flex items-center lg:space-x-10">
                                <div class="mt-5">
                                    <div class="flex lg:space-x-2 flex-wrap">
                                        <router-link :to="{ name :'OrderHistory'}" class="text-center flex justify-center rounded text-xs font-semibold  bg-brand py-3 px-6 mt-2" >Track Order</router-link>
                                        <router-link :to="`/restaurant/${getUrl}`" class="text-center flex justify-center rounded text-xs ml-2 lg:ml-0 font-semibold  bg-brand py-3 px-6 mt-2" >Place a New Order</router-link>
                                        <router-link :to="{name:'Resturants'}" class="text-center flex justify-center rounded text-xs font-semibold bg-brand py-3 px-6 mt-2" >Back to Restaurants</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div> 
    <Footer/>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Title from "@/components/Title.vue";
export default {
    name:['PaymentSuccess'],
    components:{
        Header,Footer,Title
    },
    data(){
       return{
           updated:[]
       } 
    },
    methods:{
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
    },
    computed:{
        getOrder(){
            return this.$store.getters.getOrder
        },
        getUrl(){
            return this.$store.getters.getOrderStoreName;
        }
    },
   unmounted(){
       this.$store.dispatch('removeOrder')
   },
   mounted(){
        this.$store.dispatch('emptyCart');
        this.$store.dispatch('addCountcart',0);
   }
}
</script>

<style>

</style>